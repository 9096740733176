import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { StaticImage } from "gatsby-plugin-image"

import ScrollBtn from "../button/scroll-btn"
import team from "../../images/new-images/img-abouteambanner.jpg"

import "./hero.css"

const Banner = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(relativePath: { eq: "new-images/Team-Photo-2.jpg" }) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1920
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        // className="hero-banner hero-whitefade-about"
        // preserveStackingContext
      >
        <div className="container">
          <div className="row pb-3">
            <div className="col-lg mr-auto">
              <div
                className="hero-procurement about-header about-us-hero"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <p className="text-center h5 text-light-blue text-uppercase font-weight-bold mt-0 mb-4">
                  ABOUT US
                </p>
                <h1 className="text-center page-title font-weight-medium mt-0 mb-4">
                  We Provide Technology Consulting For Growing Businesses
                </h1>
                <Link to="/about-us/#consulting">
                  <p className="h5 text-center" style={{ padding: "0.5rem 0" }}>
                    Learn More
                  </p>
                </Link>
                <div className="d-flex justify-content-center pb-4">
                  <ScrollBtn to="/about-us/#consulting" />
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      <div
        className=""
        
      >
          <img
                      placeholder="blurred"
                      className="img-fluid"
                      src={team}
                      alt="Team"
                      layout="constrained"
                      loading="lazy"
                    />
      </div>
    </>
  )
}

export default Banner
